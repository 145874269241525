import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-welcome-writing',
  templateUrl: './welcome-writing.component.html',
  styleUrls: ['./welcome-writing.component.scss']
})
export class WelcomeWritingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
