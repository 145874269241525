export const masterFirebaseConfig = {
  apiKey: "AIzaSyDpkPVxIweJbdGlTyZoHMWBXrYk_bHUDkI",
  authDomain: "john-rykken.firebaseapp.com",
  databaseURL: "https://john-rykken.firebaseio.com",
  projectId: "john-rykken",
  storageBucket: "john-rykken.appspot.com",
  messagingSenderId: "991805986112"
};

  
