import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-welcome-footer',
  templateUrl: './welcome-footer.component.html',
  styleUrls: ['./welcome-footer.component.scss']
})
export class WelcomeFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
